.cardList {
  width: 100%;
  flex-grow: 1;
  padding-bottom: 50%;
  overflow: auto;
  .filterContainer {
    padding: 4px 8px;
    .filter {
      border: 1px solid black;
      background-color: #fff;
      border-radius: 4px;
      padding: 2px;
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.3);
      height: 12px;
      margin-left: 4px;
    }
  }
  .header {
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    .result {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      color: var(--text3);
    }
    .sort {
      display: flex;
      align-items: center;
      cursor: pointer;
      .sortImg {
        width: 16px;
        height: 16px;
      }
      .sortText {
        font-size: 16px;
        margin-left: 2px;
        font-weight: 400;
        color: var(--text5);
      }
    }
  }
  .cardArea {
    width: 100%;
    padding: 0 8px;
    .card {
      position: relative;
      .cardImgArea {
        .cardImg {
          width: 100%;
          border-radius: 5px;
        }
        .cardMask {
          background-color: var(--cardSelectedCover);
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: none;
          align-items: flex-end;
          justify-content: center;
          padding: 10px;
          border-radius: 5px;
          &.selected {
            display: flex;
          }
          .cardMaskText {
            color: var(--text2);
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  }
}
