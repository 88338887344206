.describe {
  background-color: var(--white);
  display: flex;
  align-items: center;
  padding: 16px 12px;
  margin: 8px 0;
  .buySellImg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  .describeText {
    border-left: 1px solid var(--fill1);
    padding-left: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}
.cardContainer {
  background-color: var(--white);
  .totalArea {
    padding: 16px;
    .totalQuota,
    .totalAmount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .totalQuotaTitle,
      .totalAmountTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: var(--text5);
      }
      .totalQuotaValue,
      .totalAmountValue {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--text3);
      }
    }
  }
  .cardDetail {
    .item {
      background-color: var(--white);
      padding: 8px 12px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftContainer {
        height: 90px;
        display: flex;
        .image {
          width: 64px;
          height: 100%;
          border-radius: 5px;
        }
        .data {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 16px;
          .dataTitle {
            color: var(--text3);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            min-width: 20px;
            .dataValue {
              color: var(--text5);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              margin-left: 8px;
            }
          }
          .dataDescribe {
            display: flex;
            align-items: flex-start;
            .describeTitle {
              color: var(--text3);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              white-space: nowrap;
            }
            .describeValue {
              color: var(--text5);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              margin-left: 8px;
              display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 2;
              // overflow: hidden;
              // height: 40px;
              &.orange {
                color: var(--warning6);
              }
            }
          }
        }
      }
    }
  }
}
