.previewCard {
  max-width: var(--maxWidthInPc);
  position: absolute;
  display: none;
  z-index: 10000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  &.show {
    display: unset;
  }
  .container {
    width: 100vw;
    max-width: var(--maxWidthInPc);
    height: 100vh;
    padding: 0 10%;
    background-color: var(--previewBg);
    display: flex;
    align-items: center;
    .card {
      width: 100%;
      max-width: 450px;
      height: auto;
      margin: 0 auto;
      animation: popup 0.5s;
      border-radius: 10px;
    }
  }
}

@keyframes popup {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
