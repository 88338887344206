.buyOrSellContainer {
  background-color: var(--white);
  padding: 12px;
  margin-bottom: 4px;
  height: var(--orderDrawer-buySellHeight);
  display: flex;
  align-items: center;
}
.describeContainer {
  background-color: var(--white);
  margin-bottom: 10px;
  height: var(--orderDrawer-describeHeight);
}
.itemContainer {
  height: calc(
    100% - var(--orderDrawer-buySellHeight) - var(--orderDrawer-describeHeight)
  );
  overflow-y: auto;
  padding-bottom: 60px;
  .item {
    background-color: var(--white);
    padding: 8px 12px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .leftContainer {
      height: 90px;
      display: flex;
      .image {
        width: 64px;
        height: 100%;
        border-radius: 5px;
      }
      .data {
        margin-left: 16px;
        .dataName {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          height: 22px;
          line-height: 22px;
        }
        .dataFilter {
          display: flex;
          align-items: center;
          max-width: calc(100vw - 220px);
          height: 22px;
          .energy {
            width: 14px;
            height: 14px;
          }
          .mark {
            margin: 0 6px;
            width: 10px;
            height: 14px;
            border: 1px solid var(--text5);
            color: var(--text5);
            border-radius: 2px;
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
          }
          .galleries {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: var(--text4);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .type {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: var(--text4);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .dataDescribe {
          max-width: 124px;
        }
        .dataPrice {
          max-width: 124px;
          height: 22px;
          display: flex;
          align-items: center;
          .currency {
            color: var(--danger6);
            font-size: 12px;
            margin-right: 4px;
          }
        }
      }
    }
    .rightContainer {
      display: flex;
      align-items: center;
      .minus {
        width: 28px;
        height: 28px;
        border-radius: 2px;
        background-color: var(--fill2);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .quota {
        margin: 0 2px;
        height: 28px;
        width: 40px;
        border-radius: 2px;
        background-color: var(--fill2);
        display: flex;
        align-items: center;
      }
      .plus {
        width: 28px;
        height: 28px;
        border-radius: 2px;
        background-color: var(--fill2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .closeBtn {
      position: absolute;
      right: 12px;
      top: 8px;
    }
  }
}
