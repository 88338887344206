.App {
  background-color: var(--text5);
  // background-image: url("../public/images/pc_bg.png");
  // background-size: cover;
  // background-repeat: no-repeat;

  & > div {
    max-width: var(--maxWidthInPc);
    margin: 0 auto;
  }
}
.ant-drawer {
  max-width: var(--maxWidthInPc);
  margin: 0 auto;
}
.adm-dialog {
  max-width: var(--maxWidthInPc);
}
