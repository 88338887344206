.drawerBody {
  // height: 100%;
  overflow-y: scroll;
  padding-bottom: 200px;
  .container {
    padding: 8px 12px;
    .borderContainer {
      padding: 8px;
      .cardContainer {
        padding: 4px 0 2px 0;
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        .header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 42px;
          .type {
            font-size: 34px;
            font-weight: 700;
            padding: 0px 8px;
            // color: transparent;
            // background-clip: text;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            white-space: nowrap;
          }
          .comment {
            font-weight: 400;
            font-size: 14px;
          }
        }
        .cardList {
          padding: 4px 8px 0 8px;
          .cardContainer {
            text-align: center;
            padding-top: 1px;
            .card {
              position: relative;
              .image {
                width: 100%;
                position: relative;
                border-radius: 4px;
              }
              .cardData {
                position: absolute;
                z-index: 1;
                top: 40%;
                width: 100%;
                height: 60%;
                border-radius: 0 0 4px 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                // background-color: var(--orderPhotoCardCover);
                border-radius: 0 0 4px 4px;
                .comment,
                .amount {
                  display: flex;
                  align-items: center;
                  font-weight: 700;
                  font-family: "Inter";
                  &::before {
                    content: attr(data-stroke);
                    position: absolute;
                    z-index: -1;
                    -webkit-text-stroke: 2px var(--white);
                    text-stroke: 2px var(--white);
                  }
                }
                .comment {
                  min-height: 24%;
                  font-size: 2vmin;
                  position: relative;
                  color: var(--text5);
                }
                .amount {
                  min-height: 38%;
                  font-size: 5vmin;
                  color: var(--danger6);
                }
                .quota {
                  margin: 2px 0;
                  border-radius: 100%;
                  width: 32%;
                  min-height: 38%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  padding-bottom: 2px;
                  .quotaValue {
                    color: var(--white);
                    font-size: 4vmin;
                    font-weight: 700;
                    font-family: "Inter";
                  }
                }
              }
            }
            .name {
              width: 100%;
              font-size: 2vmin;
              font-weight: 400;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
.exportImgContainer {
  height: 100vh;
  position: absolute;
  top: 0;
  margin-top: 8px;
  overflow-y: scroll;
  padding-bottom: 300px;
  z-index: 10;
  background-color: var(--fill2);
  .exportImg {
    width: 100%;
  }
}
