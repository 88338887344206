* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  /* min-width: 375px; */
  overflow: hidden;
}

input::-moz-placeholder {
  color: rgb(181, 181, 190);
}

input::placeholder {
  color: rgb(181, 181, 190);
}

textarea {
  border: none;
  resize: none;
  outline: none;
}
textarea::-moz-placeholder {
  color: rgb(108, 117, 125);
}
textarea::placeholder {
  color: rgb(108, 117, 125);
}

a:visited {
  color: rgb(0, 98, 255);
}
