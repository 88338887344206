.header {
  height: var(--order-headerHeight);
  width: 100%;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  .leftContainer {
    width: 100px;
  }
  .centerContainer {
    .title {
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .rightContainer {
    width: 100px;
    display: flex;
    justify-content: flex-end;
  }
}
