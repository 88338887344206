.container {
  width: 100%;
  max-width: var(--maxWidthInPc);
  height: 100vh;
  display: flex;
  flex-direction: column;
  .topContainer {
    width: 100%;
    height: auto;
    background-image: url("../../../public/images/login_bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    .close {
      padding: 16px;
      display: flex;
      align-items: center;
      align-self: flex-end;
      cursor: pointer;
    }
    .logo {
      width: 192px;
      height: auto;
    }
  }
  .bottomContainer {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: var(--white);
    margin-top: -16px;
    border-radius: 20px 20px 0 0;
    padding: 40px 20px;
  }
}
