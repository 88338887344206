.container {
  width: 100%;
  max-width: var(--maxWidthInPc);
  transform: translate(0, 100px);
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 0px 10px 0px #00000040;
  position: absolute;
  bottom: 0px;
  transition: all 0.3s ease-in-out;
  &.showSelectCard {
    transform: translate(0, 0);
  }
  .header {
    border-radius: 16px 16px 0 0;
    padding: 8px 12px;
    background-color: var(--cardSelectHeaderBg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftContainer {
      display: flex;
      align-items: center;
      .toggleBtn {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .title {
        font-size: 16px;
        margin-left: 6px;
        font-weight: 700;
        letter-spacing: 0.3px;
      }
    }
    .rightContainer {
      display: flex;
      align-items: center;
      .cartImg {
        width: 36px;
        height: 36px;
        cursor: pointer;
      }
    }
  }
  .cardArea {
    padding: 8px 0;
    background-color: var(--white);
    .cardList {
      height: 120px;
      .card {
        height: 100%;
        width: 20px;
        &.showSelectCard {
          width: 65px;
        }
        .cardImg {
          height: 100%;
          box-shadow: -4px 0px 5px 0px #00000033;
          border-radius: 5px;
        }
      }
    }
  }
}
