.orderDetail {
  height: 100vh;
  width: 100%;
  max-width: var(--maxWidthInPc);
  background-color: var(--fill2);
  .cardDetailContainer {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 70%;
  }
}
