.submitBtn {
  border-top: 1px;
  border-color: var(--line1);
  padding: 8px 12px;
  width: 100%;
  height: 52px;
  z-index: 100;
  cursor: pointer;
  .btnText {
    background-color: var(--warning6);
    border-radius: 100px;
    color: var(--white);
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    padding: 7.5px 16px;

    &.disabled {
      opacity: 0.5;
    }
  }
}
